import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Col } from "react-bootstrap"
import StrataDocumentsModal from "./strata-documents-modal"
import TitleHeader from "../../components/title-header"

const StrataManagementDocuments = () => {
  const [currentForm, setCurrentForm] = useState("");

  const developerServices = 'developerServices'
  const rentalManagementProposalRequest = 'rentalManagementProposalRequest'
  const strataProposalRequest = 'strataProposalRequest'

  const developerServicesUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/17'
  const rentalManagementProposalRequestUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/11'
  const strataProposalRequestUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/10'
  return (
    <Layout>
      <SEO title="Strata Management | Korecki Real Estate Services" description="Korecki Real Estate Services is dedicated to providing strata corporations with the highest level of service and ensuring smooth building operations. With extensive experience in the Strata Management field, you can rely on Korecki Real Estate Services to provide sound advice and guidance." keywords="korecki,Real Estate,Strata Management services, Strata, Strata Management, Strata Property Management, Strata Management, Property Management, Client Property Account System"/>
      <div>
        <TitleHeader title={"Proposal Requests"}/>
        {/*The following code is not from contentful*/}
        <Col md={12} className="blocktext" style={{ backgroundColor: 'rgba(250,250,250)'}}>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <p className="middle justified-black" style={{fontSize: '20px'}}>Please click on and complete the relevant request form</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <div className="middle">
                <button onClick={() => setCurrentForm(strataProposalRequest)} className="btn btn-outline btn-primary-1 text-uppercase">
                  <StrataDocumentsModal
                    open={currentForm===strataProposalRequest}
                    openModal={setCurrentForm}
                    documentUrl={strataProposalRequestUrl}/>
                  Strata Management Proposal Requests
                </button>
              </div>
            </Row>
            <Row className="custom-sm-flex-order">
              <div className="middle">
                <button onClick={() => setCurrentForm(rentalManagementProposalRequest)} className="btn btn-outline btn-primary-1 text-uppercase margin-top">
                  <StrataDocumentsModal
                    open={currentForm===rentalManagementProposalRequest}
                    openModal={setCurrentForm}
                    documentUrl={rentalManagementProposalRequestUrl}/>
                  Rental Management Proposal Requests
                </button>
              </div>
            </Row>
            <Row className="custom-sm-flex-order">
              <div className="middle">
                <button onClick={() => setCurrentForm(developerServices)} className="btn btn-outline btn-primary-1 text-uppercase margin-top">
                  <StrataDocumentsModal
                    open={currentForm===developerServices}
                    openModal={setCurrentForm}
                    documentUrl={developerServicesUrl}/>
                  Developer Services Proposal Requests
                </button>
              </div>
            </Row>
          </section>
        </Col>
      </div>
    </Layout>
  )
}
export default StrataManagementDocuments
